import axios from 'axios'
import config from '@/libs/config'
import {createHttp} from './request.js'

const userAxios = axios.create({
    baseURL: config.serverApi,
    timeout: 6000,
})

const $http = createHttp(userAxios)
const post = $http.post

export const getMyAssets = (params) => {
    return post('/api/asset/list', params)
}
export const getCapacity = (params) => {
    return post('/api/asset/capacity', params)
}
export const getOSS = () => {
    return post('/api/oss/sts')
}
export const uploadAsset = (params) => {
    return post('/api/asset/upload',params)
}
export const deleteAsset = (params) => {
    return post('/api/asset/del',params)
}
export const renameAsset = (params) => {
    return post('/api/asset/rename',params)
}

// 节目
export const getProgram = (params) => {
    return post('/api/program/list',params)
}
export const createProgram = (params) => {
    return post('/api/program/create',params)
}
export const getProgramInfo = (params) => {
    return post('/api/program/info',params)
}
export const saveProgram = (params) => {
    return post('/api/program/save',params)
}
export const submitProgram = (params) => {
    return post('/api/program/submit',params)
}
export const deleteProgram = (params) => {
    return post('/api/program/del',params)
}
export const renameProgram = (params) => {
    return post('/api/program/rename',params)
}
export const getPassProgram = (params) => {
    return post('/api/program/pass/list',params)
}

// 节目单
export const getSchedule = (params) => {
    return post('/api/play/list',params)
}
export const createSchedule = (params) => {
    return post('/api/play/create',params)
}
export const renameSchedule = (params) => {
    return post('/api/play/rename',params)
}
export const getScheduleInfo = (params) => {
    return post('/api/play/info',params)
}
export const saveSchedule = (params) => {
    return post('/api/play/save',params)
}
export const publishSchedule = (params) => {
    return post('/api/play/schedule/add',params)
}
export const updateSchedule = (params) => {
    return post('/api/play/schedule/update',params)
}
export const recallSchedule = (params) => {
    return post('/api/play/schedule/recall',params)
}
export const getScheduleList = (params) => {
    return post('/api/play/schedule/list',params)
}

// 设备
export const getDevice = (params) => {
    return post('/api/device/list',params)
}
export const bindDevice = (params) => {
    return post('/api/device/bind',params)
}
export const getDeviceInfo = (params) => {
    return post('/api/device/info',params)
}
export const sendDeviceCommand = (params) => {
    return post('/api/device/cmd',params)
}
export const getDeviceSchedule = (params) => {
    return post('/api/device/schedule',params)
}
// 设备开关机日程
export const getTask = (params) => {
    return post('/api/task/list',params)
}
export const createTask = (params) => {
    return post('/api/task/create',params)
}
export const saveTask = (params) => {
    return post('/api/task/save',params)
}
export const deleteTask = (params) => {
    return post('/api/task/del',params)
}
export const syncTask = (params) => {
    return post('/api/task/sync',params)
}