
import qs from 'qs'
import store from '@/store'
import router from '@/router'

class Http {
    constructor(axios) {
        this.axios = axios
        this.request = this.request.bind(this)
        this.post = this.post.bind(this)
    }
    request(options) {
        let userInfo = store.state.app.userInfo
        let dataParams = options.data
        let data = {}
        let requestUrl = options.url
        options.method = options.method || 'get'
        let headers = {}
        if (userInfo) {
            headers.Authorization = userInfo.token
        }
        if (options.isFormData) {
            headers['Content-Type'] = 'multipart/form-data'
            data = options.data
        } else {
            for (let key in dataParams) {
                data[key] = dataParams[key]
            }
    
            if (options.method.toLowerCase() === 'get') {
                data = qs.stringify(data)
                if (data.length > 0) {
                    requestUrl += requestUrl.indexOf('?') >= 0 ? '&' : '?'
                    requestUrl += data
                }
            } else if (options.method.toLowerCase() === 'post') {
                // data = JSON.stringify(data)
                // headers['Content-Type'] = 'application/x-www-form-urlencoded'
            }
        }
    
        return new Promise((resolve, reject) => {
            this.axios({
                url: requestUrl ,
                method: options.method,
                responseType: options.responseType || 'json',
                data: data,
                headers: headers
            }).then(res => {
                if (res.data.code == '401' || res.data.code == '1000') {
                    router.replace('/login')
                    // console.log(router)
                    reject({message: '登录过期，请登录',msg: '登录过期，请登录'})
                } else if (res.data.code == '0') {
                    resolve(res.data)
                } else {
                    reject(res.data)
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status == '401') {
                        router.replace('/login')
                    }else if (error.response.status == '1000') {
                        router.replace('/login')
                    } else {
                        reject(error.response.data)
                    }
                } else {
                    // console.log(error.request)
                    // console.log(error.message)
                    reject({msg: error.message})
                }
            })
        })
    }
    post(url, params, isFormData) {
        return this.request({
            method: 'POST',
            url: url,
            data: params,
            isFormData: !!isFormData
        })
    }
}

export const createHttp = (axios)=>{
    return new Http(axios)
}