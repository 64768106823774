<template>
  	<b-sidebar
		:visible="isActive"
		bg-variant="white"
		sidebar-class="sidebar-lg"
		shadow
		backdrop
		no-header
		right
		@hidden="resetForm"
		@change="(val) => $emit('update:is-active', val)"
  	>
		<template #default="{ hide }">
			<!-- Header -->
			<div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
				<h5 class="mb-0">
					{{ activeData ? $t('Edit Play Plan') : $t('New Play Plan')}}
				</h5>

				<feather-icon
					class="ml-1 cursor-pointer"
					icon="XIcon"
					size="16"
					@click="hide"
				/>
			</div>

			<!-- BODY -->
			<validation-observer
				#default="{ handleSubmit }"
				ref="refFormObserver"
			>
				<!-- Form -->
				<b-form
					class="p-2"
					@submit.prevent="handleSubmit(onSubmit)"
					@reset.prevent="resetForm"
				>
					<!-- screen -->
					<validation-provider
						#default="validationContext"
						:name="$t('Select Screen')"
						rules="required"
						v-if="!activeData"
					>
						<b-form-group
							:label="$t('Select Screen')"
							label-for="screen"
						>
							<v-select
								id="screen"
								v-model="formData.devices"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								multiple
								label="mac"
								:options="deviceList"
								:reduce="option=>option.id"
								:placeholder="$t('Please Select')"
								:class="{'is-invalid': !!validationContext.errors[0]}"
							/>
							<b-form-invalid-feedback>
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>
					<b-form-group
						:label="$t('Screen ID')"
						v-else
					>
						{{activeData.deviceid}}
					</b-form-group>
					<!-- Begin -->
					<validation-provider
						#default="validationContext"
						:name="$t('Begin Date')"
						rules="required"
					>
						<b-form-group
							:label="$t('Begin Date')"
							label-for="begin"
						>
							<b-form-datepicker
								id="begin"
								v-model="formData.begin"
								:state="getValidationState(validationContext)"
								:max="formData.end"
								:min="today"
								v-bind="langs[calendarLocale] || {}"
								:locale="calendarLocale"
							/>
							<b-form-invalid-feedback>
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>

					<!-- End -->
					<validation-provider
						#default="validationContext"
						:name="$t('End Date')"
						rules="required"
					>
						<b-form-group
							:label="$t('End Date')"
							label-for="end"
						>
							<b-form-datepicker
								id="end"
								v-model="formData.end"
								:state="getValidationState(validationContext)"
								:min="formData.begin"
								v-bind="langs[calendarLocale] || {}"
								:locale="calendarLocale"
							/>

							<b-form-invalid-feedback>
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>
					
					<!-- Cycle Setting -->
					<validation-provider
						#default="validationContext"
						:name="$t('Cycle Setting')"
						rules="required"
					>
						<b-form-group
							:label="$t('Cycle Setting')"
							label-for="cycleSet"
						>
							<b-form-checkbox-group
								v-model="formData.cycle"
								:state="getValidationState(validationContext)"
								:options="cycleOptions"
								value-field="id"
								text-field="name"
							/>
							<b-form-invalid-feedback style="display:block !important">
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>

					<!-- Form Actions -->
					<div class="d-flex mt-2">
						<b-button
							variant="primary"
							class="mr-2"
							type="submit"
						>
							{{activeData ? $t('Save') :$t('Add')}}
						</b-button>
						<b-button
							type="button"
							variant="outline-secondary"
							@click="hide"
						>
							{{$t('Cancel')}}
						</b-button>
					</div>
				</b-form>
			</validation-observer>
		</template>
  	</b-sidebar>
</template>

<script>
import {mapState} from 'vuex'
import {
  	BSidebar, BForm, BFormGroup, BFormInput, BInputGroup, BFormInvalidFeedback, BButton, BFormDatepicker, BFormCheckboxGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import {formatDate, formatCycle} from '@/libs/utils'
import {getDevice,publishSchedule, updateSchedule} from '@/libs/apis/serverApi'
import config from '@/libs/config'

export default {
	components: {
		BSidebar,
		BForm,
		BFormGroup,
		BFormInput,
		BInputGroup,
		BFormInvalidFeedback,
		BButton,
		BFormDatepicker,
		BFormCheckboxGroup,

		// Form Validation
		ValidationProvider,
		ValidationObserver,

		vSelect
	},
	model: {
		prop: 'isActive',
		event: 'update:is-active',
	},
	props: {
		isActive: {
			type: Boolean,
			required: true,
		},
		playid: {
			type: String,
			required: true,
		},
		activeData: {
			type: Object,
			default () {
				return null
			}
		}
	},
	computed: {
		...mapState({
			locale: state=>state.app.locale
		}),
		calendarLocale () {
			if(this.locale=='zh_CN'){
				return 'zh'
			}
			return this.locale
		},
		today () {
			return formatDate(new Date(),'YYYY-MM-DD')
		},
		
	},
	data() {
		return {
			required,
			langs: {
				'zh': {
					labelPrevDecade: '过去十年',
					labelPrevYear: '上一年',
					labelPrevMonth: '上个月',
					labelCurrentMonth: '当前月份',
					labelNextMonth: '下个月',
					labelNextYear: '明年',
					labelNextDecade: '下一个十年',
					labelToday: '今天',
					labelSelected: '选定日期',
					labelNoDateSelected: '未选择日期',
					labelCalendar: '日历',
					labelNav: '日历导航',
					labelHelp: '使用光标键浏览日期',
					labelHours: '小时',
				},
			},
			deviceList: []
		}
	},
	setup(props, { emit }) {
		console.log(props)
		let blankFormData = {
			devices: [],
			playid: props.playid,
			begin: '',
			end: '',
			cycle: []
		}
		console.log(props.activeData)
		if(props.activeData){
			blankFormData = {
				id: props.activeData.id,
				begin: props.activeData.begin,
				end: props.activeData.end,
				cycle: formatCycle(props.activeData.cycle).map(item=>item.id)
			}
		}

		const formData = ref(JSON.parse(JSON.stringify(blankFormData)))
		const resetformData = () => {
			formData.value = JSON.parse(JSON.stringify(blankFormData))
		}
		

		const {
			refFormObserver,
			getValidationState,
			resetForm,
		} = formValidation(resetformData)

		const cycleOptions = config.cycleList

		return {
			formData,
			cycleOptions,
		
			refFormObserver,
			getValidationState,
			resetForm,
		}
	},
	created () {
		getDevice({offset: 0,limig: 20}).then(res=>{
			this.deviceList = res.rows
		})
	},
	methods: {
		getCycle(cycles){
			let temp = [0,0,0,0,0,0,0,0]
			cycles.forEach(item=>{
				temp[item] = 1
			})
			let str = temp.join('')
			return parseInt(str,2)
		},
		createData () {
			const cycle = this.getCycle( this.formData.cycle)
			const params = {
				devices: this.formData.devices.map(item=>{
					return {id:item}
				}),
				base: {
					playid: this.formData.playid,
					begin: this.formData.begin,
					end: this.formData.end,
					cycle: cycle
				}
			}
			publishSchedule(params).then(res=>{
				this.$emit('refetch-data')
				this.$emit('update:is-active', false)
			},err=>{
				this.$toast.error(err.message)
			})
		},
		editData () {
			const cycle = this.getCycle( this.formData.cycle)
			const params = {
				id: this.formData.id,
				begin: this.formData.begin,
				end: this.formData.end,
				cycle: cycle
			}
			updateSchedule(params).then(res=>{
				this.$emit('refetch-data')
				this.$emit('update:is-active', false)
			},err=>{
				this.$toast.error(err.message)
			})
		},
		onSubmit(){
			if(this.activeData){
				this.editData()
			}else {
				this.createData()
			}
		}
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
