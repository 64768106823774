<template>
	<b-card no-body>
		<b-card-header>
			<div class="d-flex align-items-center">
				<b-card-title>
                    {{$t('Play Plan Name')}}: Test
				</b-card-title>
			</div>
			<div>
				<b-button class="mr-1" variant="primary" @click="newAction">{{$t('New Play Plan')}}</b-button>
				<!-- <b-button class="mr-1" variant="success" @click="saveAction">{{$t('Save')}}</b-button>
				<b-button class="mr-1" variant="warning" @click="publishAction">{{$t('Publish')}}</b-button>
				<b-button variant="outline-secondary" @click="cancelAction">{{$t('Cancel')}}</b-button> -->
			</div>
		</b-card-header>
		<b-card-body>
			<b-table
				ref="priceList"
				hover
				:items="dataList"
				:fields="tableColumns"
				primary-key="id"
				show-empty
				:empty-text="$t('No Data')"
				stacked="sm"
				
			>
				<!-- Column: range -->
				<template #cell(range)="data">
					{{data.item.begin}} 至 {{data.item.end}}
				</template>
				<!-- Column: cycle -->
				<template #cell(cycle)="data">
					{{ formatCycle(data.item.cycle).map(item=>item.name).join(',')}} 
				</template>
				<!-- Column: status -->
				<template #cell(status)="data">
					<span class="text-success" v-if="data.item.status ==1">已发布</span>
					<span class="text-error" v-if="data.item.status == -1">已过期</span>
				</template>
				<!-- Column: Actions -->
				<template #cell(actions)="data">
					<!-- 编辑 -->
					<feather-icon
						:id="`${data.item.id}-edit-icon`"
						icon="EditIcon"
						class="cursor-pointer"
						size="16"
						@click="editAction(data.item)"
					/>
					<b-tooltip
						:title="$t('Edit')"
						class="cursor-pointer"
						:target="`${data.item.id}-edit-icon`"
					/>
					

					<!-- 撤回 -->
					<feather-icon
						:id="`${data.item.id}-delete-icon`"
						icon="RotateCcwIcon"
						class="cursor-pointer ml-1"
						size="16"
						@click="recallAction(data.item)"
					/>
					<b-tooltip
						:title="$t('Recall')"
						class="cursor-pointer"
						:target="`${data.item.id}-delete-icon`"
					/>
				</template>
			</b-table>
		</b-card-body>
		<!-- New  -->
		<New
			:is-active.sync="newSidebarVisible"
			v-if="newSidebarVisible"
			:playid="playId"
			:activeData="activeData"
			@refetch-data="getData()"
		/>
		<Function>
			<ul class="colors-list list-unstyled mb-0">
				<li>
					<feather-icon
						size="15"
						icon="ChevronsRightIcon"
					/>
					<span>{{$t('Function Declaration')}}1</span>
				</li>
				<li>
					<feather-icon
						size="15"
						icon="ChevronsRightIcon"
					/>
					<span>{{$t('Function Declaration')}}2</span>
				</li>
			</ul>
		</Function>
	</b-card>
</template>
<script>
import { BButton, BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BImg, BTable, BTooltip } from 'bootstrap-vue'
import New from "@/components/schedule/PublishNew.vue"
import { getScheduleList, recallSchedule } from "@/libs/apis/serverApi";
import {formatCycle} from "@/libs/utils"

export default {
	components: {
		BButton,
		BRow,
		BCol,
		BCard,
		BImg,
		BCardHeader,BCardBody,
		BCardTitle,
		BTable,
		BTooltip,
		New
	},

    data () {
        return {
			playId: this.$route.query.id,
            tableColumns: [
                { key: 'id', },
                { key: 'deviceid', label: this.$t('Screen ID') },
                { key: 'range', label: this.$t('Date Range') },
                { key: 'cycle', label: this.$t('Cycle') },
                { key: 'status', label: this.$t('Status') },
                { key: 'actions', label: this.$t('Actions') },
            ],
            loading: false,
			dataList: [],
			pageSize: 12,
			currentPage: 1,
			activeData: null,
            newSidebarVisible: false,
        }
    },
    created(){
		if(!this.playId){
			this.$router.back()
			return
		}
        this.getData(1)
    },
    methods: {
		formatCycle,
        getData (page) {
			this.loading = true
			page = page || 1;
			getScheduleList({
				playid: this.playId,
				offset: (page - 1) * this.pageSize,
				limit: this.pageSize,
			}).then((res) => {
				this.loading = false
				this.dataList = res.rows;
				this.currentPage = page;
			}, err=>{
				this.loading = false
				this.$toast(err.message)
			});
        },
        newAction () {
			this.activeData = null
            this.newSidebarVisible = true
        },
        editAction(item){
			this.activeData = item
            this.newSidebarVisible = true
        },
        saveAction () {
            // 
        },
        publishAction () {
            // 
        },
        cancelAction () {
            this.$router.back()
        },
        recallAction(item){
            this.$bvModal.msgBoxConfirm(this.$t('recallDataTip'), {
                title: this.$t('Tips'),//'Please Confirm',
                size: 'sm',
                okVariant: 'danger',
                okTitle: this.$t('Recall'),
                cancelTitle: this.$t('Cancel'),
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
            })
            .then(isConfirm => {
				if(isConfirm){
					recallSchedule ({id: item.id}).then(res=>{
						this.$toast(this.$t('Recall Success'))
						this.getData(this.currentPage)
					},err=>{
						this.$toast.error(err.message)
					})
				}
                
            })
        }
	}
}
</script>